import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { alpha, useTheme } from '@mui/material/styles';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from "gatsby"
import RouterLink from "components/RouterLink";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import IconButton from '@mui/material/IconButton';

const NavItem = ({ title, id, items, horizontal , type, mt, ml, colorInvert = false }) => {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState(null);
  const [openedPopoverId, setOpenedPopoverId] = useState(null);

  const handleClick = (event, popoverId) => {
    setAnchorEl(event.target);
    setOpenedPopoverId(popoverId);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenedPopoverId(null);
  };

  const [activeLink, setActiveLink] = useState('');
  useEffect(() => {
    setActiveLink(window && window.location ? window.location.pathname : '');
  }, []);

  const hasActiveLink = () => items.find((i) => i.href === activeLink);
  const linkColor = colorInvert ? 'common.white' : 'text.primary';

  return (
    <Box>
      <Box
        display={'flex'}
        alignItems={'center'}
        aria-describedby={id}
        sx={{ cursor: 'pointer' }}
        onClick={(e) => handleClick(e, id)}
      >
				{ title == 'Github' || title == 'Githubs' || title == 'Githubs-Footer' ? (
					<IconButton component={"a"} target="_blank" color="primary">
						<svg t="1644994985336" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="19553" width={{Githubs:"20","Githubs-Footer":"30"}[title]} height={{Githubs:"20","Githubs-Footer":"30"}[title]}><path d="M465.981 1001.74a89.578 89.578 0 0 1-89.889-88.955l-0.356-63.666c-176.662 38.422-213.927-74.93-213.927-74.93-28.895-73.372-70.523-92.873-70.523-92.873-57.61-39.446 4.364-38.645 4.364-38.645 63.71 4.452 97.28 65.447 97.28 65.447 56.631 97.058 148.569 69.009 184.765 52.803 5.699-41.049 22.172-69.053 40.337-84.947-141.045-16.028-289.303-70.523-289.303-313.79 0-69.32 24.799-125.952 65.447-170.429-6.589-16.028-28.316-80.584 6.144-168.025 0 0 53.337-17.052 174.659 65.09a609.28 609.28 0 0 1 158.943-21.37c53.915 0.223 108.276 7.257 159.031 21.37C814.186 6.679 867.434 23.73 867.434 23.73c34.549 87.485 12.822 152.042 6.233 168.025 40.693 44.433 65.358 101.109 65.358 170.43 0 243.89-148.57 297.583-289.926 313.299 22.751 19.723 43.053 58.323 43.053 117.582 0 84.992-0.312 119.674-0.312 119.674a89.622 89.622 0 0 1-89.8 89H465.98z" fill={linkColor} p-id="19554" data-spm-anchor-id="a313x.7781069.0.i24" ></path></svg>
					</IconButton>
				) : (
						<Typography
							sx={{ 
								width: '100%',
								padding: type == 'child'?'10px 8px':null,
								fontSize: type == 'child'?'14px':null,
							}}
							fontWeight={openedPopoverId === id || hasActiveLink() ? 700 : 400}
							color={linkColor}
						>
							{title}
						</Typography>
				)}
				{ type == 'child' ? (
					<ArrowForwardIosIcon
						sx={{
							position: 'absolute',
							right: 10,
							width: 12,
							height: 12,
							color: linkColor,
						}}
					/>
				) : title != 'Github' && title != 'Githubs' && title != 'Githubs-Footer' && (
					<ExpandMoreIcon
						sx={{
							marginLeft: theme.spacing(1 / 4),
							width: '16px !important',
							height: '16px !important',
							transform: openedPopoverId === id ? 'rotate(180deg)' : 'none',
							color: linkColor,
						}}
					/>
				)}
      </Box>
      <Popover
        elevation={3}
        id={id}
        open={openedPopoverId === id}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: horizontal?horizontal:'top',
          horizontal: type == 'child' ? 'left' : 'center',
        }}
        sx={{
          '.MuiPaper-root': {
            maxWidth: items.length > 12 ? 350 : 250,
            padding: 2,
            marginTop: mt ? mt : 2,
						marginLeft: ml ? ml : null,
            borderTopRightRadius: 0,
            borderTopLeftRadius: 0,
            borderBottomRightRadius: 8,
            borderBottomLeftRadius: 8,
            borderTop: `3px solid ${theme.palette.primary.main}`,
          },
        }}
      >
        <Grid container spacing={0.5}>
          {items.map((p, i) => (
            <Grid item key={i} xs={items.length > 12 ? 6 : 12}>
							{!p.children?(
								<Button
									component={p.target=='_blank'?'a':Link}
									to={RouterLink(p.href)}
									target={p.target}
									href={p.href}
									fullWidth
									sx={{
										justifyContent: 'flex-start',
										color:
											activeLink === p.href
												? theme.palette.primary.main
												: theme.palette.text.primary,
										backgroundColor:
											activeLink === p.href
												? alpha(theme.palette.primary.main, 0.1)
												: 'transparent',
										fontWeight: activeLink === p.href ? 600 : 400,
									}}
								>
									{p.title}
									{p.isNew && (
										<Box
											padding={0.5}
											display={'inline-flex'}
											borderRadius={1}
											bgcolor={'primary.main'}
											marginLeft={2}
										>
											<Typography
												variant={'caption'}
												sx={{ color: 'common.white', lineHeight: 1 }}
											>
												new
											</Typography>
										</Box>
									)}
								</Button>
							):(
								<Button
								  title={p.title}
								  items={p.children}
								  ml={p.ml}
								  mt={p.mt}
									type="child"
								  colorInvert={colorInvert}
									component={NavItem}
									fullWidth
									sx={{
										justifyContent: 'flex-start',
										color:
											activeLink === p.href
												? theme.palette.primary.main
												: theme.palette.text.primary,
										backgroundColor:
											activeLink === p.href
												? alpha(theme.palette.primary.main, 0.1)
												: 'transparent',
										fontWeight: activeLink === p.href ? 600 : 400,
									}}
								>
								</Button>
							)}
            </Grid>
          ))}
        </Grid>
      </Popover>
    </Box>
  );
};

NavItem.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  colorInvert: PropTypes.bool,
	type: PropTypes.string,
	ml: PropTypes.number,
	mt: PropTypes.number,
	horizontal: PropTypes.string,
};

export default NavItem;
