const LANGS = ['en', 'zh', 'jp'];
const RouterLink = (to) => {
	let prefered = 'en';
	try {
		prefered = navigator.language
	} catch {
	}
	const lang = LANGS.find(s => prefered.startsWith(s)) || 'en';
	let hrefSplit = to.split("/");
	if ( hrefSplit.length >= 2 && hrefSplit[1] == "docs" ) {
		hrefSplit[1] = `docs/${lang}`
	}
	const href = hrefSplit.join("/")
	return href;
};

export default RouterLink;
